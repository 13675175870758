/* https://css-tricks.com/box-sizing/#article-header-id-6 */
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: "Klavika", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2 {
  font-weight: 500; /* medium */
}
h1 {
  font-size: 20px;
}
h2 {
  font-size: 15px;
}
input,
textarea,
select,
button {
  font-family: inherit;
  font-size: inherit;
}
a {
  color: inherit;
  text-decoration: none;
}
button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  outline: none;
  text-transform: none;
  -webkit-appearance: button;
  font-size: inherit;
  font-family: inherit;
  letter-spacing: inherit;
}
label,
input[type="checkbox"] {
  cursor: pointer;
}

/* More reset rules could be added from https://github.com/necolas/normalize.css/blob/master/normalize.css */
