@font-face {
  font-family: "Klavika";
  src: url("/fonts/klavika-regularitalic.woff2") format("woff2"),
    url("/fonts/klavika-regularitalic.woff") format("woff");
  font-weight: 400; /* normal */
  font-style: italic;
}
@font-face {
  font-family: "Klavika";
  src: url("/fonts/klavika-regular.woff2") format("woff2"),
    url("/fonts/klavika-regular.woff") format("woff");
  font-weight: 400; /* normal */
  font-style: normal;
}
@font-face {
  font-family: "Klavika";
  src: url("/fonts/klavika-mediumitalic.woff2") format("woff2"),
    url("/fonts/klavika-mediumitalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Klavika";
  src: url("/fonts/klavika-medium.woff2") format("woff2"),
    url("/fonts/klavika-medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Klavika";
  src: url("/fonts/klavika-lightitalic.woff2") format("woff2"),
    url("/fonts/klavika-lightitalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Klavika";
  src: url("/fonts/klavika-light.woff2") format("woff2"),
    url("/fonts/klavika-light.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Klavika";
  src: url("/fonts/klavika-bolditalic.woff2") format("woff2"),
    url("/fonts/klavika-bolditalic.woff") format("woff");
  font-weight: 700; /* bold */
  font-style: normal;
}
@font-face {
  font-family: "Klavika";
  src: url("/fonts/klavika-bold.woff2") format("woff2"),
    url("/fonts/klavika-bold.woff") format("woff");
  font-weight: 700; /* bold */
  font-style: normal;
}
